import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@organisms/layout'
import styled from 'styled-components'
import Heading from '@particles/heading'
import Text from '@particles/text'
import SEO from '@atoms/seo'
const ContributeLayout = styled(Layout)`
  .freebirdFormviewerViewHeaderThemeStripe {
    width: 0;
  }
`

const ContributePage = ({ location }) => (
  <ContributeLayout location={location}>
    <SEO title="Contribute" location={location} />
    <Heading h1 margin="T4 B1.5">
      Contribute
    </Heading>
    <Text size={1.25} style={{ lineHeight: '2.25rem' }}>
      Awesome! Thank you for raising your hand! By contributing a metric here,
      you will be helping thousands of current businesses and the future
      generation of entrepreneurs.
    </Text>
    <Text size={1.25} margin="T1 B6">
      And if that&apos;s not enough, if you get involved, props will be coming
      your way. Thank you.
    </Text>
    <iframe
      frameBorder="0"
      height="600"
      marginHeight="0"
      marginWidth="0"
      src="https://docs.google.com/forms/d/e/1FAIpQLSf3yFW4rexp_i-pFHzXrZGQAzgKcYk2ti00J7QVr2bV4Sj5OQ/viewform?embedded=true"
      width="100%"
    >
      Loading...
    </iframe>
  </ContributeLayout>
)
ContributePage.propTypes = {
  location: PropTypes.any
}

export default ContributePage
